.slider-container3 {
    background-color: #419be0;
    padding: 40px;
    width:100%;

}

.profile-user-card{
    display:flex;
    flex-direction: row;
    align-items: center;
    padding:12px;
    border-bottom:1px solid black;
    
 }

 .call-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:12px;
    margin-bottom:15px;
 }
 .profile-details-card{
        display: flex;
        flex-direction: column;
        margin-left: 20px;
 }
 .profile-icon{
    font-size:40px;
    margin-right: 20px;
   
 }
 .call-now-btn{
    width:150px;
    height:32px;
    cursor:pointer;
    border:none;
    background-color:transparent;
    border:1px solid blue;
    border-radius:6px;
    
 }
 .view-profile-btn{
    border:none;
    color:blue;
    cursor:pointer;background-color: transparent;
 }

 .user-card{
    border:1px solid black;
    background-color: 'azure';
   border-radius:8px;
   width:33%;
   margin-right:15px
 }
 .mobile-device{
    display:none;
 }

 .large-device{
    display:block;
 }

 @media screen and (max-width:986px){
    .user-card{
        border:1px solid black;
        background-color: 'azure';
       border-radius:8px;
       width:100%;
       margin-right:15px
     }
     .large-device{
        display:none;
     }
     .mobile-device{
        display:block;
     }
 }

 .mobile-carousel-card{
    border:1px solid black;
    background-color: azure;
    width:80%;
    padding:12px;
    border-radius:8px;
 }