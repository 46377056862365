.contact-page-responsive{
    width:83%;
}

.contact-page-container {
    margin-top: 120px;
    background-color: rgb(42, 59, 65);
    border-radius: 8px;
   
    display: flex;
    flex-direction: row;
    box-shadow: rgb(255, 0, 0) 0px 5px 5px 0px;
}

.contact-about-container {
    display: flex;
    flex-direction: column;
    color: white;
    padding-top: 45px;
    padding-bottom: 35px;
    padding-left: 35px;
    border-right: 1px solid skyblue;
    padding-bottom: 45px;
}

.contact-form-bg-container {
    border: none;
    padding-top: 45px;
    padding-bottom: 45px;
    padding-right: 35px;
    padding-left: 35px;
    width: 50%;
}


.contact-heading {
    font-size: 30px;
    font-family: "Roboto";
    font-weight: 700;
    color: rgb(216, 62, 62);

}

.contact-border1 {
    border: 1px solid rgb(255, 0, 0);
    width: 80px;
}
.contact-border {
    border: 1px solid white;
    width: 80px;
}
.contact-description {
    font-size: 21px;
    font-family: initial;
    margin-top: 22px;
}

.contact-follow-us {
    display: flex;
    flex-direction: 'row'
}

.phone-container {
    display: flex;
    flex-direction: 'row';
    align-items: center;

}

.email-card {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
}

.pnone-number-card {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
}

.contact-follow-us-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgb(255, 255, 255);
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 8px;
    border-radius: 8px;
}

.logo-circle {
    border: 1px solid rgb(255, 255, 255);
    
    border-radius: 50%;
    padding: 10px;
}

.phone-logo {
    font-size: 26px;

}

.contact-type {
    font-size: 20px;
    font-family: "Roboto";
    margin-bottom: 0px;
    margin-top: 12px;
}

.contact-value {
    font-size: 18px;
    font-family: "Roboto";
    margin-top: 0px;
}

.leave-msg-heading {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Roboto';
    color: rgb(216, 62, 62);

}

.contact-follow-us {
    margin-top: 40px;
    display: flex;
    flex-direction: 'row';
    align-items: center;
}

.contact-follows-heading {
    font-size: 25px;
    font-family: "Roboto";
    font-weight: 600;

}

.contact-follow-us-icon {
    margin-left: 35px;

}

.contact-social-icon {
    color: white;

    font-size: 26px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.active-contact-social-icon {
    color: rgb(216, 62, 62);
    font-size: 26px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.contact-social-icon2 {
    color: white;
    font-size: 29px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.active-contact-social-icon2 {
    color: rgb(216, 62, 62);
    font-size: 29px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 35px;
}

.contact-input-box {
    width: 80%;
    border: none;
    border-bottom: 2px solid rgb(255, 0, 0);
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(0, 0, 0);
    outline: none;

}

.contact-input-box1 {
    width: 80%;
    border: none;
    border-bottom: 2px solid rgb(255, 0, 0);
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(0, 0, 0);
    outline: none;

}

.active-contact-input-box {
    width: 80%;
    border: none;
    border-bottom: 2px solid rgb(216, 62, 62);
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(0, 0, 0);
    outline: none;
}


.contact-submit-btn {
    width: 140px;
    margin-top: 30px;
}

.required {
    margin-top: 0px;
}

.textarea-contactpage {
    resize: none;
}

@media screen and (max-width:986px) {

    .contact-page-responsive{
        width:93%;
    }
    
    .contact-page-container {
        margin-bottom: 20px;
    }

    .contact-about-container {
        display: flex;
        flex-direction: column;
        color: white;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 35px;
        border-bottom: 1px solid skyblue;

    }

    .contact-border {
        border: 1px solid white;
        width: 90px;
    }

    .contact-form-bg-container {
        border: none;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-right: 35px;
        padding-left: 35px;
        width: 100%;
    }

    .contact-page-container {
        margin-top: 120px;
        background-color: rgb(42, 59, 65);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        box-shadow: rgb(216, 62, 62) 0px 4px 12px;
        margin-right: 3px;
        margin-left: 3px;
    }

    .contact-input-box {
        width: 95%;

    }

    .contact-input-box1 {
        width: 95%;
        resize: none;
    }

    .active-contact-input-box {
        width: 95%;
        border: none;
        border-bottom: 3px solid rgb(216, 62, 62);
        background-color: transparent;
        padding-top: 8px;
        padding-bottom: 8px;
        color: rgb(0, 0, 0);
        outline: none;
    }

    .contact-heading {
        font-size: 25px;

    }

    .contact-follows-heading {
        font-size: 20px;
        font-family: "Roboto";
        font-weight: 600;

    }

    .leave-msg-heading {
        font-size: 25px;
    }

    .contact-type {
        font-size: 18px;
    }

    .contact-value {
        font-size: 15px;
    }

    .contact-follow-us-icon {
        margin-left: 5px;
        margin-top: 8px;

    }

    .contact-follow-us {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-follows-heading {
        font-size: 20px;
        font-family: "Roboto";
        font-weight: 600;
        color: rgb(216, 62, 62);
    }


}

.map-container {
    display:flex;
    flex-direction:column;
    box-shadow: lightgray 0px 5px 5px 0px;
  
    width:83%;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 25px;
    
    margin-top:30px;
}

@media screen and (max-width:480px) {  

    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}