.Add-product-form-container {
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin-bottom:25px;
    background-color: rgb(248, 243, 243);
    margin-top: 25px;
    padding: 20px;
}

.add-product-input {
    width: 80%;
    height: 38px;
    margin-top: 30px;
    border-radius: 5px;
    background-color: transparent;
}

.add-product-file {
    border: 2px solid black;
    width: 80%;
    height: 34px;
    margin-top: 30px;
    border-radius: 5px;
}

.upload-btn {
    width: 170px;
    margin-top: 25px;
    color: white;
}

textarea {
    width: 50%;
    margin-top: 30px;
    border-radius: 5px;
    align-self: flex-start;
    background-color: transparent;
}

.add-product-heading {
    font-family: "Roboto";
    color: white;
    text-align: center;
    padding-right: 20px;
    
}

.add-product-form-bg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:80px;
  
}



@media screen and (max-width:986px){
    .add-product-input{
        width: 90%;
        
    }
    textarea{
        width:70%;
    }
    .add-product-file{
        width:90%;
    }
    
}