.admin-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 10px;
}

.admin-login-heading{
    text-align:center;
    font-size:28px;
    width: 60%;
}