.close-card {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 80%;
    margin-top: 25px;

    background-color: #0a0d4b;
}

.referral-out-main-container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 102px;

}

.new-referral-popup {
    height: 350px;
}



.select-input {
    width: 70%;
    height: 38px;
    border-radius: 8px;
}

.referral-btn-container {
    margin-top: 80px;
    text-align: center;
}

.referral-btn {
    width: 160px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 1px;
    background-color: transparent;
    color: black;
    font-family: 'Roboto';
    font-weight: 600;
}

.product-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    min-height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 25px;
    border-style: outset;

    background-color: white;
    border-radius: 8px;
}

.product-card {
    width: 85%;
    border: 2px solid #0a0d4b;
    background-color: white;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;
    color: black;
    flex-direction: row;
    margin-bottom: 30px;
}

.referral-out-product-card {
    width: 85%;
    border: 2px solid #0a0d4b;
    background-color: white;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;
    color: black;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.referral-out-product-card:hover{
    background-color:rgb(250, 241, 241);
}


.product-card:hover {

    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    transition: 0.6s;
}

.filter-product-card:hover{
    background-color:#023051;
    color:white;
    border-color:white;
}

.filter-product-card {
    width: 100%;
    border: 2px solid #0a0d4b;
    background-color: white;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;

    flex-direction: row;
    margin-bottom: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

}

.filter-mobile-img {
    width: 30%;
    height: 250px;
    border-radius: 8px;
    margin: 8px;
}

.content-referal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;

}

.product-description {
    overflow-y: auto;

}

.mobile-img {
    width: 30%;
    height: 250px;
    border-radius: 8px;
    margin: 8px;
}

.mobile-content {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    padding-right: 8px;


}

.mobile-types {
    font-size: 22px;
    font-weight: 500;
    font-family: 'Roboto';
    margin-right: 5px;
}

.product-type {
    margin-top: 18px;
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto";
    color: rgb(17, 9, 56);
    margin-bottom: 25px;
}

.email-btn {
    width: 90px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: black;
    border-radius: 4px;
    color: white;
}

.whatsapp-btn {
    width: 120px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: white;
    border-radius: 4px;
    margin-top: 15px;
    color: black;
    margin-left: 10px;
}

.email-whatsapp-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    border-left: 1px solid black;
}

.dropdown-content {
    margin-top: 40px;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    margin-left: 15px;
    padding: 0px;
    border-radius: 6px;
}

.price-referral-out {
    font-size: 18px;
}

.discount-price {
    text-decoration: line-through;
    text-decoration-color: red;
    position: relative;
    top: -1rem;
    font-size: 15px;


}

.new-pop-head {
    margin-top: 80px;
    text-align: center;
}

.discount-percentage {
    position: relative;
    top: -1rem;
    font-size: 15px;
    margin-left: 12px;
}

/* Links inside the dropdown */
.dropdown-content li {
    color: black;
    text-align: center;
    text-decoration: none;
    display: block;
}

.self-customer {
    cursor: pointer;
    font-weight: 700;
}

/* .self-customer:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    transition:0.6s;
  } */


@media screen and (min-width:560px) and (max-width:985px) {
    .th-data {
        color: #17a2b8;
        font-size: 14px;
        font-family: "Roboto";
    }

    .td-data {
        color: rgb(13, 10, 70);
        font-size: 12px;
        font-family: monospace;

    }
}

@media screen and (max-width:986px) {
    .referral-btn-container {
        margin-top: 10px;
        text-align: center;
    }


    .table {
        width: 100%;
        margin-left: 0px;
        padding-left: 0px;
    }

    .th-data {
        color: #17a2b8;
        font-size: 10px;
        font-family: "Roboto";
    }

    .td-data {
        color: rgb(13, 10, 70);
        font-size: 8px;
        font-family: monospace;

    }

    .content-referal-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #0a0d4b;
    }

    .close-card {
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        width: 90%;
        margin-top: 25px;

    }

    .referral-container {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .referral-btn {
        width: 120px;
        outline: none;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 20px;
        margin-top: 10px;
        text-align: center;
        color: black;
        font-family: 'Roboto';
        font-weight: 600;
    }

    .product-card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        min-height: 100vh;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin-bottom: 25px;
        border-style: outset;
        background-color: white;
        border-radius: 8px;
    }

    .product-card {
        width: 95%;

        margin-top: 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    .filter-product-card {
        width: 95%;

        margin-top: 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

    }

    .referral-out-product-card {
        width: 90%;
        border: 2px solid #0a0d4b;
        background-color: white;
        margin-top: 15px;
        border-radius: 8px;
        display: flex;
        color: black;
        flex-direction: column;

        margin-bottom: 30px;
    }


    .mobile-img {
        width: 95%;
        height: 217px;
        border-radius: 8px;
    }

    .filter-mobile-img {
        width: 95%;
        height: 217px;
        border-radius: 8px;
    }

    .mobile-content {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        padding-right: 8px;
        border-bottom: 1px solid black;

        border-right: none;
    }

    .mobile-types {
        font-size: 18px;
        font-weight: 500;
        font-family: 'Roboto';
        margin-right: 5px;
    }

    .product-type {
        margin-top: 18px;
        font-size: 20x;
        font-weight: bold;
        font-family: "Roboto";
        color: rgb(17, 9, 56)
    }

    .email-btn {
        width: 90px;
        border: none;
        cursor: pointer;
        outline: none;
        background-color: black;
        border-radius: 4px;
        color: white;
        margin-top: 15px;
    }

    .whatsapp-btn {
        width: 120px;
        border: none;
        cursor: pointer;
        outline: none;
        background-color: white;
        border-radius: 4px;
        margin-top: 15px;
        color: black;
        margin-left: 10px;
    }

    .email-whatsapp-cards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
        border-left: none;
        border-top: 2px solid black;
        margin-bottom: 25px;
    }

    .dropdown-content {
        margin-top: 40px;
        position: absolute;
        background-color: #f1f1f1;

    }
}

@media screen and (min-width:600px) and (max-width:984px) {
    .mobile-img {
        width: 99%;
        height: 360px;
        border-radius: 8px;
    }

}

.referral-to-customer-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.referral-input {
    width: 80%;
    height: 38px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.referral-email-drop {
    background: linear-gradient(90deg, rgba(69, 42, 90, 1) 40%, rgba(217, 55, 13, 1) 100%);
}

tr {
    text-align: center;
}

th {
    color: #17a2b8;
    font-size: 16px;
    font-family: "Roboto";
}

td {
    color: rgb(13, 10, 70);
    font-size: 14px;
    font-family: monospace;

}

.referral-out-product-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg-card {
    background-color: rgb(250, 241, 241);
}

.text-color {
   background-color: #023051;
    color:white;
    font-weight: bold;
    font-size: 28px;
}

button.close{
    color:white;
}