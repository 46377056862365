.about-page-container{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    margin-top:90px;
    
}
.about-us-heading{
    font-size:25px;
    font-family: 'Courier New';
    font-weight:bold;
}
.about-us-description{
    font-size:19px;
    font-family:initial;
}
.benefits-heading{
    font-size:22px;
    font-family:'Roboto';
    color:#d9370d;
}



.about-img-card{
    display: flex;
    flex-direction: row;
    align-items:center;
    background-color:#1c1f18;
}


/* .management-image{

    
}
.card-container{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:40%;
}

.our-mission-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius:8px;
    padding:15px;
    border:none;
   box-shadow: #d9370d 1.95px 1.95px 2.6px;
   margin-bottom:20px;
   margin-right:20px;
}
.card-img{
    width:80%;
    height:210px;
    margin-bottom:0px;
}

.our-object-heading{
    font-size:24px;
    color:black;
    text-align: center;
    margin-top: 0px;
    margin-bottom:15px;
  
}  */

/* .our-object-description{
    font-size:16px;
    font-family:initial;
    color:black;
    text-align: center;
}  */


/* Varma  13-11-2024 */
/* General Styles */


body {
    font-family: Arial, sans-serif;
}

.about-page-container {
    padding: 20px;
}

.about-us-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-us-description {
    font-size: 1.1rem;
    line-height: 1.6;
}

.benefits-heading {
    font-size: 1.2rem;
    margin-top: 30px;
    font-weight: bold;
}

.benefit-list {
    margin-left: 20px;
}

.benefit-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin: 8px 0;
}

.benefit-item svg {
    margin-right: 8px;
    color: #d9370d;
}

.management-image {
    max-width: 100%;
    height: auto;
}

.card-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.our-mission-card {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    margin-bottom: 40px;
}

.card-img {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 20px;
}

.our-object-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px 0;
}

.our-object-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
}


@media (max-width: 992px) {
    .about-us-heading {
        font-size: 1.8rem;
    }

    .about-us-description,
    .benefit-item {
        font-size: 1rem;
    }

    .benefit-item svg {
        font-size: 1.2rem;
    }

    .management-image {
        width: 100%;
        height: auto;
    }

    .our-mission-card {
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .about-us-heading {
        font-size: 1.6rem;
    }

    .about-us-description {
        font-size: 0.95rem;
    }

    .about-us-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .about-img-card {
        flex-direction: column;
    }

    .card-container {
        flex-direction: column;
        align-items: center;
    }

    .card-content {
        padding: 15px;
    }

    .benefit-item {
        font-size: 0.9rem;
    }

    .our-object-heading {
        font-size: 1.2rem;
    }

    .our-object-description {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .about-us-heading {
        font-size: 1.4rem;
    }

    .about-us-description {
        font-size: 0.9rem;
    }

    .benefits-heading {
        font-size: 1.1rem;
    }

    .card-content {
        padding: 10px;
    }

    .our-object-heading {
        font-size: 1.1rem;
    }

    .our-object-description {
        font-size: 0.95rem;
    }
} 


@media (max-width: 768px) {
    .carddd {
        width: 85%;
        margin: 10px auto;
    }
    .row {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .carddd {
        width: 100%;
        margin: 10px auto;
    }
}