.button__bar li.slick-active button {
    opacity: .75;
    width: 3px;
    color: #000
}

.ft-slick__dots--custom {
    position: relative;
    top: -1.3rem;
}

.slick-dots .slick-active {
    position: relative;
    top: -1.4rem;
    color: gray;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
    width: 2rem;
    height: 5rem;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.slick-prev::before,
.slick-next::before {
    display: none;
}

.slick-prev {
    left: 6px;
    border-radius: 2px;
}

.slick-next {
    right: 6px;
    border-radius: 2px;
}

.img1 {
    background-image: url('../Images/ad1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    height: 18vh;
    width: 100%;
}

.img2 {
    background-image: url('../Images/ad1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    height: 18vh;
    width: 100%;

}

.img3 {
    background-image: url('../Images/ad1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    height: 18vh;
    width: 100%;
}

.img4 {
    background-image: url('../Images/ad1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    height: 18vh;
    width: 100%;
}

.img5 {
    background-image: url('../Images/ad1.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 18vh;
    width: 100%;
}