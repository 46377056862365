

.store-product-detail-img{
    height:470px;

    border-radius:6px;
    margin-right:15px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.detail-view-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:100vh;
    margin-top:80px;
    margin-bottom:30px;
}

.product-details-name{
    font-family:'Roboto'

}

.product-details-cost{
    font-size:20px;
    margin-top:20px;
    font-weight:600;
}

.product-details-self-btn{
    color:white;
    height:34px;
    border-radius:5px;
    cursor:pointer;
    outline:none;
    background-color: rgba(69, 42, 90, 1);
    margin-right:12px;
}
.product-detail-orginal-cost{
    margin-top:0px;
    text-decoration: line-through;
    text-decoration-color: #d9370d;
    margin-right:10px;
    font-size:16px;
    position: relative;
    top:-0.3rem;
   
}

.product-details-customer-btn{
    color:white;
    height:34px;
    border-radius:5px;
    cursor:pointer;
    outline:none;
    background-color:#d9370d;
    margin-right:12px;
}

.product-detail-discount{
    margin-right:10px;
    font-size:16px;
    position: relative;
    top:-0.3rem;
    color:green;
}

.product-details-validty{
    font-size:18px;
    font-weight:600;
    margin-top:15px;
    font-family:'Roboto';
}

.product-details-description-head{
    font-size:20px;
    font-family:'Roboto';
    font-weight:600;
}

.product-details-description{
    font-family: initial;
    font-size:16px;
   
    overflow-y: auto;
}

.detail-content-card{
    margin-left:0px;
    height:470px;
    padding:10px;
    border:1px solid lightgrey;
    border-radius:6px;
    background-color: azure;
    overflow-y: auto;
   
}

.img-cards{
    display:flex;
    flex-direction:row;
}

.small-img{
    width:70px;
   border:0.0001rem light black;
    border-radius:3px;
    margin-top:2px;
    height:80px;
    cursor:pointer;
}

.small-images-cards{
    display:flex;
    flex-direction:column;
    list-style-type:none;

}

.thumbnail-image-container{
        display:flex; 
        flex-direction:row;

}

@media screen and (max-width:986px){
    .store-product-detail-img{
        height:50vh;
        margin-bottom:0px;
    }
    .detail-content-card{
        height:50vh;
        width:94%;
        margin-top:15px;
        margin-bottom:25px;
    }
    .thumbnail-image-container{
        display:flex; 
        flex-direction:column;
    }  
    .small-images-cards{
        display:flex;
         flex-direction: row;
        order:1;
    }
    .small-img{
        width:30%;
    }
    .store-product-detail-img{
        margin-top:20px;
    }

}