.img {
    width: auto;
    height: 200px;
}

.cards1 {
    display: flex;
    justify-content: center;
    
    width: 100%;
    flex-wrap: wrap;
}

.card1 {
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    object-fit: cover;
}

.info {
   
    border-top: none;
}