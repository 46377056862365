.dashboard-cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.kyc-btn {
    margin-top: 110px;
    color: goldenrod;
}

@media screen and (min-width:986px) {
    .dashboard-cards-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

    }
}

.dashboard-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;


    border-radius: 8px;


}

.dashboard-card {
    border: 1px solid #e3000f;
    width: 20%;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    margin-right: 15px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-card:hover {

    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color: #023b5d;
    color: white;
    transition: 0.6s;
}


.referral-out-btn {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    background-color: #d9370d;
    color: white;
    height: 30px;
    width: 150px;
    margin-bottom: 15px;
}

.referral-type-heading {
    font-size: 19px;

    margin-top: 15px;
    font-weight: 600;


}

.logo-heading {
    font-size: 30px;
    font-weight: bold;
    margin-left: 20px;
}

.navbar2 {

    background: white;
    /* background: linear-gradient(90deg, #062d59 0%, #545a61 100%); */
    height: 48px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-top: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    top: 4px;
    z-index: 1000;
    padding-top: 20px;
}

@media screen and (max-width:986px) {
    .search-input-container-navbar {
        display: none;
    }

    .logo-heading {
        font-size: 16px;
        font-weight: bold;
        margin-left: 20px;
    }

    .filter {
        display: none;
    }

    .navbar2 {
        display: none;
    }

    .hide-navbar {
        display: none;
    }

    .mobile-space {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .dashboard-cards-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


    }

    .dashboard-cards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;


        border-radius: 8px;


    }

    .dashboard-card {
        border: 1px solid rgb(32, 47, 187);
        width: 30%;
        text-align: center;
        padding: 10px;
        border-radius: 6px;
        margin-right: 15px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        height: 150px;
        display: flex;
        flex-direction: column;

        align-items: center;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .dashboard-card:hover {

        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

        transition: 0.6s;
    }






    .referral-out-btn {
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 3px;
        background-color: #d9370d;
        color: white;
        height: 30px;
        width: 150px;
        margin-bottom: 15px;
    }

    .referral-type-heading {
        font-size: 17px;
        font-family: 'Roboto';
        margin-top: 15px;
        font-weight: 600;
    }
}


@media screen and (max-width:380px) {
    .dashboard-cards-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


    }

}

.cssanimation,
.cssanimation span {
    animation-duration: 3s;
    animation-fill-mode: both;
}

.cssanimation span {
    display: inline-block
}

.fadeInBottom {
    animation-name: fadeInBottom
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1
    }
}


.link {
    color: #023051;
}




/* .active-link1 {
    font-weight: bold;
    color: red;
    font-size: 12px;  
  } */
  
  .active-link {
    font-weight: bold;
    color: red;
    
  }