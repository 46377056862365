@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Caveat:wght@500&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@500;600&display=swap');


.v-con-logo {
    font-family: 'Heebo', sans-serif;
    padding: 3px;
}

.v-con-logo-con {
    border-radius: 1.4rem;
}

.v-con-logo-con:hover{
    border-radius: 1.4rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}


.v-store-t {
    font-family: 'Caveat', cursive;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(128, 98, 98);
    opacity: 1; /* Firefox */
    font-weight: 500;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(255, 87, 87);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: red;
  }

  .sign-in-btn{
    border-radius: 1.4rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .sign-in-btn:hover{
    background-color: rgb(161, 76, 76);
    border-radius: 1.4rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .menu-link{
    max-width: max-content;
    font-size: 1rem;
    position: relative;
    cursor: pointer;
  }

  .menu-link::after{
    content: "";
    position: absolute;
    bottom: -4px;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: white;
    transition: 0.3s;
    transition-timing-function: ease-in-out;
    transform: scaleX(0);
    border-radius: 0.2rem;
  }

  .menu-link:hover::after{
    transform: scaleX(1);
  }

  .nav-container, .footer{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .nav-container{
    position: fixed;
    top: 0px;
  }

  .shop-reg {
    display: none;
    transition: 0.6s;
  }


  .shop-reg-con:hover > .shop-reg{
    display: flex;
    transition: 0.4s;
  }
.shop-reg-con{
  height: 21rem;
}

  @media screen and (max-width: 900px) {
    .image img{
      width: 50vw;
    }
 
  }

  @media screen and (max-width: 888px) {
    .image img{
      width: 30vw;
    }
 
  }


  @media screen and (max-width: 898px) {
    .image img{
      width: 30vw;
    }
    .shop-reg-con{
      width: 97vw;
    }
 
  }

  @media screen and (max-width: 620px) {
    
    .shop-reg-con{
      width: 97vw;
    }
 
  }
  @media screen and (max-width: 650px) {
    
    .shop-reg-con{
      height: 17rem;
    }
 
  }

  @media screen and (max-width: 450px) {
    
    .shop-reg-con{
      height: 14rem;
    }
 
  }