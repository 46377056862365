.input-box {
    padding: 10px;
    border: 1px solid black;
    width: 90%;
    height: 38px;
}

.success-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: green;
    font-size: 35px;
    font-family: initial;
    height: 100vh;
}

.log-button {
    background-color: rgb(13, 10, 70);
    width: 150px;
    color: white;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
    margin-top: 30px;
    margin-bottom: 20px;
    background: rgb(69, 42, 90);
    background: linear-gradient(90deg, rgba(69, 42, 90, 1) 40%, rgba(217, 55, 13, 1) 100%);
}


.create-button {
    background-color: rgb(13, 10, 70);
    color: white;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 20px;
    width: 210px;
    background: rgb(69, 42, 90);
    background: linear-gradient(90deg, rgba(69, 42, 90, 1) 40%, rgba(217, 55, 13, 1) 100%);

}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-top: 40px;
    /* background: rgb(69, 42, 90);
    background: linear-gradient(90deg, rgba(69, 42, 90, 1) 40%, rgba(217, 55, 13, 1) 100%); */
}

.login-card {
    border: 1px solid black;
    border-radius: 5px;
    margin: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
}

.box-shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

span {
    font-size: 2rem;

}



@media screen and (max-width:986px) {
    .login-logo {
        display: none;
    }

    .login-card {
        display: flex;
        flex-direction: column;
        border: none;
        background-color: none;
        width: 95%;
    }

    .login-heading {
        font-size: 18px;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 25px;
        margin-left: 32px;
    }

    .log-button {

        width: 90px;

    }

    span {
        font-size: 18px;

    }


    .create-button {


        width: 180px;
        font-size: 16px;

    }

    .login-form-card {
        width: 100%;
    }


}


@media screen and (max-width:480px) {  

    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}

.pss-btn{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}



/* varma */


.container {
    display: flex;
    width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.436);
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
}
.left {
    background-color: white;
    color: red;
    width: 350px;
    
    padding: 10px;
    text-align: center;
  
}
.left img {
    width: 100%;
    max-width: 250px;
    margin-bottom: 20px;
    
}
.left h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
.left p {
    font-size: 14px;
    line-height: 1.6;
}

.divider::after {
    margin-left: 10px;
}


@media (max-width: 768px) {
    .container {
        flex-direction: column;
        width: 90%;
    }
    .left, .right {
        padding: 20px;
    }
    .left img, .right img {
        max-width: 150px;
    }
    .left h2, .right h2 {
        font-size: 20px;
    }
    .left p, .right p {
        font-size: 12px;
    }
}