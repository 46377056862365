.slider-container {
    background-color: #419be0;
    padding: 40px;
    width:100%;

}

.business-image{
    width:52%;
    height:470px;
    margin-left: 60px;
    border-radius:5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 120px;
}

.content-card{
    width:43%;
    margin-left:30px;
    margin-top: 120px;
    height:470px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:20px;
    align-items: center;
    border:1px solid lightcyan;
    border-radius:5px;
    background-color:rgb(225, 240, 240);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-number{
    color:rgb(49, 45, 252);
    margin-top:8px;
   

}

.merchant-profile{
    color:rgb(49, 45, 252);
    margin-top:8px;
 
}

.call-icon{
    font-size:25px;
    margin-right:12px;
    color:black;
}

.business-description{
    font-size:18px;
    font-family:initial;
    overflow-y: auto;
}
.carousel-card{
    display:flex;
    flex-direction:row;
    align-items:center;
}
.carousel-bottom-buttons{
    display:flex;
    flex-direction:row;
    align-items:center;
}
.contact-card{
    border:1px solid rgb(252, 45, 159);
    border-radius:8px;
    padding-left: 12px;padding-right:12px;
   
    height:40px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    margin-right:15px;
}

@media screen and (max-width:986px){
 
    .business-image{
        width:100%;
        height:250px;
        margin-left: 10px;
        border-radius:5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    
    .content-card{
        width:92%;
        margin-left:10px;
        height:250px;
        margin-top:10px;
        font-size:14px;
}

.call-icon{
    font-size:15px;
    margin-right:px;
    color:black;
    text-align: center;
}

}

.mobile-device{
    display:none;
 }

 .large-device{
    display:block;
 }

 @media screen and (max-width:986px){
    .user-card{
        border:1px solid black;
        background-color: 'azure';
       border-radius:8px;
       width:100%;
       margin-right:15px
     }
     .large-device{
        display:none;
     }
     .mobile-device{
        display:block;
     }
 }