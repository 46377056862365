.li-item {
    width: 80px;
    height: 80px;
  }
  
  /* .btn {
    width: 60px;
    height: 60px;
    border-style: none;
    background-color: transparent;
  }
   */
  .small-image {
    width: 60px;
    height: 60px;
  }
  
  .highlight {
    opacity: 0.5;
  }

  .small-img{
    width:70px;
   border:0.0001rem light black;
    border-radius:3px;
    margin-top:2px;
    height:80px;
    cursor:pointer;
    border:1px solid gray;
    margin-bottom:5PX;
}

.active-tab-item{
    width:70px;
  
     border-radius:3px;
     margin-top:2px;
     height:80px;
     cursor:pointer;
     border:3px solid blue;
     margin-bottom:5PX;
}

@media screen and (max-width:986px){
    .small-img{
        width:30%;
        height:80px;
    }
    .active-tab-item{
        width:30%;
        height:80px;
    }

}