.referral-in-container {
    margin-top: 102px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

tr {
    text-align: center;
}

th {
    color: #17a2b8;
    font-size: 16px;
    font-family: "Roboto";
}

td {
    color: rgb(13, 10, 70);
    font-size: 14px;
    font-family: monospace;

}

.pop-up-img {
    border-radius: 6px;
    height: 230px;
}


.mobile-typ {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 16px;
}

.referral-in-heading {
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: bold;
}

.email-whatsap-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.whatsap-btn {
    width: 120px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: white;
    border-radius: 4px;

    color: black;
    margin-left: 10px;
}

.emai-btn {
    width: 90px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: black;
    border-radius: 4px;
    color: white;
}

.aceept-close-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    
}

.border-accept-btn{
    border-left: 1px solid black;
}

@media screen and (min-width:560px) and (max-width:985px) {
    .th-data {
        color: #17a2b8;
        font-size: 16px;
        font-family: "Roboto";
    }

    .td-data {
        color: rgb(13, 10, 70);
        font-size: 14px;
        font-family: monospace;

    }
}


@media screen and (max-width:986px) {
    th {
        font-size: 15px;
    }

    td {
        font-size: 14px;
    }

    .referral-in-heading {
        margin-bottom: 25px;
        font-size: 25px;
        text-align: center;
        font-family: 'Roboto';
        font-weight: bold;
    }

    .aceept-close-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-left: 1px solid black;
        margin-top:0px;
    }


    .popup-content {
        width: 95%;

    }

    .email-whatsap-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        border-left: none;
    }

    .pop-up-referral-cont {
        height: 280px;
        border-top:1px solid black;
    }


    .pop-up-img {
        height: 210px;
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 10px;
        border-radius: 5px;
        margin-top:15px;
    }

    .email-whatsap-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        margin-top: 0px;
        padding-top: 0px;
        border-left: none;
    }

    .mobile-typ {
        margin-bottom: 0px;
        padding-bottom: 0px;
        font-size: 16px;
    }


}


@media screen and (max-width:986px) {

    .popup-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: lightgray;
        width: 80%;
        height: 610px;
        border-radius: 5px;
        overflow-y: auto;

    }

    .pop-up-img {
        margin: auto;
        margin-bottom: 20px;
        margin-top:15px;
    }
}

@media screen and (min-width:986px) {

    .popup-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: lightgray;
        width: 80%;
        height: 60vh;
        border-radius: 5px;
        margin-top: 30px;
        border: none;
    }

    .pop-up-referral-cont {
        height: 280px;
        margin-left: 15px;
        border-right:1px solid black;
     
    }
}


@media screen and (max-width:396px) {
    .pop-up-img {
        margin: auto;
        margin-top:15px;
        margin-bottom: 20px;
        
    }


}


.referral-in-product {
    width: 100%;
    border: 2px solid #0a0d4b;
    background-color: white;
    margin-top: 15px;
 
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items:center;
    margin-bottom: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

}
