.mystore-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-store-container {
    width: 99.2%;
    text-align: center;
    border-radius: 3px;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-top: 140px;
    /* background-color: azure; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    margin-bottom: 30px;
    border: 8px solid #02375a;
}

.products-store-container1 {
    width: 99.2%;
    text-align: center;
    border-radius: 3px;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-top: 30px;
    /* background-color: azure; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    margin-bottom: 30px;
    border: 8px solid #02375a;
}

.store-head-card {
    background-color: #02375a;
    margin-left: 5px;
    margin-right: 5px;
}

.add-product-btn {
    margin-top: 30px;

}

.store-product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 10px;
}

.store-product-card {
    /* background-color:rgb(158, 223, 228); */
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    margin-top: 15px;
    margin-left: 10px;
    width: 30%;
    border: 1px solid #f5c67a;
}

.mystore-product-card {
    width: 24%;
    margin-left: 10px;
    border: 1px solid lightgray;
    margin-top: 15px;
    border-radius: 6px;
    background-color: white;
    height: 27rem;
}

.card-hover {
    border-top:1px solid #02375a;
    margin-top: 15px;
}

.store-product-img {
    width: 47%;
    border-radius: 8px;
    margin: 8px;

}

.store-product-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    margin-left: 10px;
    margin-top: 15px;
}

.store-product-name {
    margin-bottom: 10px;
    font-size: 19px;
    margin-top: 10px;

}



.discount-amount {
    font-size: 16px;
    text-decoration: line-through;
    text-decoration-color: red;
    margin-bottom: 12px;
}

.validity {
    font-weight: 600;

}

.store-product-price {
    font-weight: 600;
    font-size: 14px;
}

.store-product-discount {
    font-weight: 600;

}

.my-store-heading {
    color: #02375a;
}

.store-products-container {
    width: 100%;
}


@media screen and (max-width:986px) {
    .mystore-product-card {
        width: 85%;
    }

    .products-store-container {
        width: 100%;
        text-align: center;
        border-radius: 8px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 80px;
        background-color: azure;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }

    .store-product-card {
        width: 95%;
    }
}

@media screen and (min-width:539px) and (max-width:986px) {
    .store-product-img {
        height: 340px;
        border-radius: 8px;
    }
}
@media screen and (max-width:500px){
    .img{
        height: 170px;
    }
    .item{
        height: 440px;
    }
}