 .customer-details {
     font-size: 18px;
 }


 .input-firstname {
     margin: 15px;
     border-radius: 5px;
     width: 230px;
     height: 35px;


 }



 .email-input-box {
     margin: 15px;
     border-radius: 5px;
     width: 340px;
     height: 35px;
 }

 .card {
     border: 1px solid lightslategray;
 }

 .text-align {
     text-align: end;
 }



 .bids-card {
     width: 100%;
     height: 23vh;
     padding: 10px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     background-color: white;
 }


 .shadow1 {
     box-shadow: 0px 0.9px 0.9px 0px #f8d50d;

 }

 .shadow2 {
     box-shadow: 0px 0.9px 0.9px 0px rgb(13, 10, 70);

 }

 .shadow3 {
     box-shadow: 0px 0.9px 0.9px 0px #17a2b8;

 }


 .shadow4 {
     box-shadow: 0px 0.9px 0.9px 0px gray;


 }

 .card-container {
     margin-bottom: 0px;
     padding-bottom: 0px;
     min-height: 55vh;
     background: #eceff1;
     background: linear-gradient(15deg, rgb(224, 224, 224) 0%, rrgb(233, 237, 254)100%);
 }

 p {
     font-family: "Roboto";
 }


 .main_container {
     background: #eceff1;
     background: linear-gradient(15deg, rgb(224, 224, 224) 0%, rrgb(233, 237, 254)100%);
 }