.referral-close-container {
    margin-top: 102px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup-input {
    border-radius: 5px;
    height: 35px;
    margin-bottom: 30px;
    width: 40%;
}

.referral-close-pop-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 101px;
}

.pop-up-close {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.user-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-top: 30px;
    margin-bottom: 30px;
}

.user-profile-icon {
    font-size: 60px;

    color: rgb(252, 211, 74);
}

.user-profile-card {
    margin-left: 120px;
    background-color: rgba(35, 65, 129, 1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.profile-heading {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 28px;
    color: white;

    margin-top: 20px;
}

.owner-type {
    font-family: initial;
    font-weight: 400;
    font-size: 22px;
    color: white;
}

.user-location {
    font-family: initial;
    font-weight: 400;
    font-size: 20px;
    color: white;
    align-items: center;
}


.user-contact {
    color: white;
}

.edit-btn {
    background-color: rgb(252, 211, 74);
    color: rgba(35, 65, 129, 1);
    font-weight: 500;
    margin-top: 25px;

}

.location-hq {
    font-size: 15px;
    border-radius: 4px;
    background-color: #17a2b8;
    margin-right: 12px;
    padding: 4px;
    padding-right: 8px;
}

.gst-cards-container {
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    background-color: #17a2b8;
    margin-left: 0px;

}

.gst-card {
    border: 1px solid lightgray;
    background-color: rgb(248, 245, 245);
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    border-radius: 8px;
    margin-bottom: 25px;
    ;
}

.get-aadhar-head {
    font-family: "Roboto";
    font-size: 25px;
    font-weight: 500;
}

.verify-btn {
    border: 1px solid gray;
    ;
    cursor: pointer;
    outline: none;
    width: 120px;
    color: rgb(175, 26, 26);
    border-radius: 6px;
    background-color: rgb(248, 193, 144);
}

.aadhar-img {
    margin-left: 20px;
    margin-right: 40px;
}

.user-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 110px;

    margin-top: 20px;

}

.user-icon {
    font-size: 45px;
}

.user-card-container {
    border: 1px solid gray;
    margin-top: 40px;
    border-radius: 8px;
    padding: 0px;
    margin: 0px;
    margin-top: 30px;
    background-color: rgb(248, 245, 245);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.profile-details-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.user-profile-data-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}

/* 
.width{
    width:80%;
} */
.profile-data-card {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.profile-type {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 21px;
}

.profile-value {
    font-family: initial;
    font-weight: 400;
    font-size: 18px;

}

.edit-btn2 {
    background-color: rgb(252, 211, 74);
    color: rgba(35, 65, 129, 1);
    font-weight: 500;
    margin-top: 25px;
    margin-left: 50px;
}

.user-name {
    color: #17a2b8;
    font-size: 26px;

    margin-top: 8px;
    font-family: 'Roboto';

    ;
}

.user-type {
    color: rgb(252, 211, 74);
}

.pop-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: lightgray;
    width: 100%;
    height: 470px;
    border-radius: 5px;
    border: none;

}

.form-bg-container {
    width: 100%;

}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;

    width: 70%;
    height: 350px;
}

.update-form-input1 {
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 10px;
    width: 50%;
    height: 35px;
}

.update-form-input2 {
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50%;
    height: 35px;
}

.update-form-input3 {
    border-radius: 5px;
    height: 35px;
    margin-bottom: 30px;
    width: 80%;
}

.update-form-input4 {
    border-radius: 5px;
    height: 35px;
    margin-bottom: 30px;
    width: 80%;
}

.update-form-heading {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
    color: #17a2b8;
}

body {
    overflow-x: hidden;
}

tr {
    text-align: center;
}

@media screen and (max-width:986px) {
    .user-card {

        margin-left: 20px;

        margin-top: 20px;

    }

    .user-profile-data-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
    }

    .user-profile-container {

        min-height: 100vh;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .user-profile-card {
        margin-left: 1px;

    }

    .user-name {
        color: #17a2b8;
        font-size: 18px;
        margin-top: 8px;
        font-family: 'Roboto';
        margin-right: 10px;

    }

    .edit-btn2 {

        margin-top: 25px;
        margin-left: 10px;
    }

    .pop-cont {
        width: 90%;
    }

    .get-aadhar-head {
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 500;
    }

    .profile-type {
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 18px;
    }

    .profile-value {
        font-family: initial;
        font-weight: 400;
        font-size: 14px;

    }

    .profile-details-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .profile-data-card {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}

.about-user-icon {
    font-size: 45px;
    ;
}

.about-profile-card {
    display: flex;
    flex-direction: column;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    border-radius: 8px;
}

.inputfile+label {
    font-size: 1.01em;
    font-weight: 400;
    color: white;
    background-color: black;
    display: inline-block;
    border-radius: 8px;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 8px;
    text-decoration: underline;
    cursor: pointer;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: rgb(34, 154, 202);
}

.referral-close-table-img {
    height: 35px;
    width: 25px;
}

.refer-close-prod-more-details-btn {
    font-size: 11px;
    font-weight: 600;
    color: black;

}

.product-description {
    overflow-y: auto;

}

.referral-close-in-product {
    width: 100%;
    border: 2px solid #0a0d4b;
    background-color: white;
    margin-top: 15px;

    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.referral-close-in-product:hover {
    background-color: #023051;
    color: white;
}

.product-typ{
    margin-top: 18px;
    font-size: 25px;
    font-weight: bold;
    font-family: "Roboto";

    margin-bottom: 25px;
}

.pop-up-referral-cont {
    height: 280px;
    margin-left: 15px;
    border-right:1px solid black;
    
}

.pop-up-referral-cont:hover {
    height: 280px;
    margin-left: 15px;
    border-right:1px solid white;
    border-top:1px solid white;
}